<template>
  <section>
    <div class="text-h3 title">
      {{ $t("history") }}
    </div>
    <div class="label">
      {{ $t("charge_history") }}
    </div>
    <charge-session
      :show-details="false"
      :per-page-options="[5, 20, 50]"
      :headers="_headers"
      @fetch="(v) => (filters = v)"
    />

    <!-- <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      no-filters
      @filterOption="onFiltersChange"
    > -->
    <!-- add Filters -->
    <!-- <template #addFilters>
        
      </template> -->
    <!-- add Filters -->

    <!-- Action Modals -->
    <!-- <template #actionModals> </template> -->
    <!-- Action Modals -->
    <!-- </ListingLayout> -->
  </section>
</template>

<script>
// import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    // ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    chargeSession: () =>
      import("@/views/modules/charge-session/sub-screen/ChargeSession.vue"),
  },
  // mixins: [ListingLayoutMixins],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    dateType: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      filters: {},
      subFilters: {},

      headers: [
        {
          text: this.$t("session_id"),
          align: "left",
          sortable: false,
          value: "interval_id",
        },
        {
          text: this.$t("date_time"),
          align: "left",
          sortable: false,
          value: "start_datetime",
        },
        {
          text: this.$t("charge_gain"),
          align: "left",
          sortable: false,
          value: "charge_gain",
        },
        {
          text: this.$t("duration"),
          align: "left",
          sortable: false,
          value: "duration",
          showAdmin: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "mdMakes/isLoadingMdMakes",
      meta: "mdMakes/meta",
      list: "mdMakes/listMdMakes",
    }),
    _headers() {
      // computed headers for showing columns to role based
      return this.headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    dateType() {
      this.fetchData();
    },

    filters() {
      this.fetchData();
    },
  },
  // beforeDestroy() {
  //   const params = {
  //     name: "md_makes",
  //     filters: this.options,
  //   };
  //   this.$store.commit("redirect/SET_FILTERS", params);
  // },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const imei = this.$route.params.imei;
      const params = {
        ...this.filterOptions,
        ...this.filters,
        imei: imei,
      };
      await this.$store
        .dispatch("vehicleChargeSession/list", params)
        .then(() => {});
    },
  },
};
</script>
<style lang="sass" scoped>
.title
  font-weight: 600
.label
  padding-left: 1rem
  padding-top: 1rem
  font-size: 1.2rem
</style>
